import React from "react"

import ModalWithProvidedBody from "./ModalWithProvidedBody"

export type LoginHelpModalProps = {
  forgotPasswordPath?: string
  confirmationInstructionsPath?: string
  unlockInstructionsPath?: string
}

const LoginHelpModal: React.FC<LoginHelpModalProps> = ({
  forgotPasswordPath,
  confirmationInstructionsPath,
  unlockInstructionsPath,
}) => {
  return (
    <>
      <ModalWithProvidedBody
        buttonText="Trouble Logging In?"
        buttonClassName="p-0 max-w-max border-0 bg-transparent text-red-500 hover:underline"
        modalClassName="bootstrap-modal"
        modalTitle="Login Help"
      >
        {({ closeModal }) => (
          <>
            <div className="modal-body">
              {forgotPasswordPath && (
                <div className="mt-1">
                  If you forgot your password,{" "}
                  <a href={forgotPasswordPath}>reset it here.</a>
                </div>
              )}
              {confirmationInstructionsPath && (
                <div className="mt-1">
                  For confirmation instructions,{" "}
                  <a href={confirmationInstructionsPath}>go here.</a>
                </div>
              )}
              {unlockInstructionsPath && (
                <div className="mt-1">
                  For instructions to unlock your account,{" "}
                  <a href={unlockInstructionsPath}>go here.</a>
                </div>
              )}
              <div className="mt-6">
                For all other issues,{" "}
                <a href="tel:+18002111986">call 800-211-1986.</a>
              </div>
            </div>

            <div className="modal-footer">
              <div className="flex w-full !justify-center">
                <button className="btn solid blue" onClick={closeModal}>
                  Okay
                </button>
              </div>
            </div>
          </>
        )}
      </ModalWithProvidedBody>
    </>
  )
}

export default LoginHelpModal
