import React from "react"

import NotificationContainer from "components/NotificationContainer"

import Modal from "./Modal"

const ClearLessonPlans = ({ organizationId }) => {
  return (
    <>
      <NotificationContainer />
      <Modal organizationId={organizationId} />
    </>
  )
}

export default ClearLessonPlans
