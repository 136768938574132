import React from "react"

import { useFormikContext } from "formik"

import LocalTime from "components/LocalTime"

const FormFailures = () => {
  const { status } = useFormikContext()

  if (!Array.isArray(status)) return null
  return (
    <>
      {status.map((error, index) => {
        let alertClass
        switch (error.type) {
          case "info":
            alertClass = "alert-info bg-blue-400"
            break
          case "success":
            alertClass = "alert-success bg-green-600"
            break
          default:
            alertClass = "alert-danger"
        }
        return (
          <div key={index} className={`alert ${alertClass} my-3 !mr-0 p-3`}>
            <div>
              <p className="m-0">
                {typeof error.message === "string" &&
                error.message.match("<a href") ? (
                  <span dangerouslySetInnerHTML={{ __html: error.message }} />
                ) : (
                  error.message
                )}
              </p>
              {error.conflictingSessions && (
                <ul className="list-unstyled mb-2">
                  {error.conflictingSessions.map(session => (
                    <li key={session.id}>
                      <a
                        href={session.showPath}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="text-white"
                      >
                        <LocalTime timestamp={session.startsAt} />
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default FormFailures
