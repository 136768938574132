import React, { useEffect, useRef, useState } from "react"

import { Form, Formik } from "formik"
import moment from "moment-timezone"
import { gql } from "urql"
import { pipe, subscribe } from "wonka"

import classNames from "src/classNames"
import createBlob from "src/createBlob"
import client from "src/urql-client"

import {
  AutocompleteField,
  DateRangeField,
  FormFailures,
  SubmitButton,
  handleFailure,
} from "components/Forms/Formik/hookComponents"
import ModalWithProvidedBody from "components/ModalWithProvidedBody"

export type TailoredAssignmentUserExportProps = {
  tailoredAssignmentId?: string
}

const TailoredAssignmentUserExport: React.FC<
  TailoredAssignmentUserExportProps
> = ({ tailoredAssignmentId }) => {
  const formState = {
    organizationId: null,
    tailoredAssignmentId: tailoredAssignmentId || null,
    dateRange: [],
  }
  const [csvUrl, setCsvUrl] = useState<null | string>()
  const csvDownloadLink = useRef<HTMLAnchorElement>()

  const handleExport = (values, actions, closeModal) => {
    const { dateRange, ...rest } = values
    const params = { ...rest, startDate: null, endDate: null }
    const [startDate, endDate] = dateRange
    if (startDate) {
      params.startDate = moment(startDate.toDate()).startOf("day").format()
      params.endDate = moment(startDate.toDate()).endOf("day").format()
    }
    if (endDate) {
      params.endDate = moment(endDate.toDate()).endOf("day").format()
    }

    const { unsubscribe } = pipe(
      client.subscription(exportSubscription, params),
      subscribe(result => {
        if (result.error) {
          handleFailure(actions, [{ message: result.error?.message }])
          actions.setSubmitting(false)
          return
        }

        const { status, failures, csv } =
          result.data.tailoredAssignmentUserExport
        if (failures.length > 0) {
          handleFailure(actions, failures)
          actions.setSubmitting(false)
          unsubscribe()
          return
        }

        if (status === "done") {
          const blobUrl = createBlob(csv, "text/csv")
          setCsvUrl(blobUrl)
          actions.setSubmitting(false)
          closeModal()
          unsubscribe()
        } else if (status) {
          actions.setStatus(status)
        }
      })
    )
  }

  useEffect(() => {
    if (!csvUrl) return
    csvDownloadLink.current.click()
    setCsvUrl(null)
    setTimeout(() => URL.revokeObjectURL(csvUrl), 0)
  }, [csvUrl])

  return (
    <>
      <a
        ref={csvDownloadLink}
        href={csvUrl}
        download="tailored assignments export.csv"
        className="hidden"
      >
        csv
      </a>

      <ModalWithProvidedBody
        modalTitle="Tailored Assignments Export"
        buttonText={tailoredAssignmentId ? "Export Results" : "Export"}
        buttonClassName={classNames(
          "btn btn-info",
          tailoredAssignmentId && "btn-sm"
        )}
      >
        {({ closeModal }) => (
          <Formik
            initialValues={formState}
            onSubmit={(values, actions) =>
              handleExport(values, actions, closeModal)
            }
          >
            <Form>
              <div className="modal-body">
                <AutocompleteField
                  name="organizationId"
                  label="Organization"
                  api="/api/admins/organizations/autocomplete_name"
                />
                <DateRangeField name="dateRange" label="Date Submitted" />
              </div>

              <div className="modal-footer flex-col">
                <FormFailures />
                <div className="flex w-full justify-between">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-danger"
                  >
                    Cancel
                  </button>
                  <SubmitButton text="Export" />
                </div>
              </div>
            </Form>
          </Formik>
        )}
      </ModalWithProvidedBody>
    </>
  )
}

const exportSubscription = gql`
  subscription TailoredAssignmentUserExport(
    $organizationId: ID
    $tailoredAssignmentId: ID
    $startDate: DateTime
    $endDate: DateTime
  ) {
    tailoredAssignmentUserExport(
      organizationId: $organizationId
      tailoredAssignmentId: $tailoredAssignmentId
      startDate: $startDate
      endDate: $endDate
    ) {
      status
      csv
      failures {
        message
      }
    }
  }
`

export default TailoredAssignmentUserExport
