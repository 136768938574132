import React from "react"

import ReactTable from "react-table"

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ordered from "src/ordered"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import Modal from "components/Modal"

import "react-table/react-table.css"

const AdminPrepaidPackagesTable = () => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/prepaid_packages",
  })

  const booleanSymbol = props =>
    props.value ? (
      <FontAwesomeIcon icon={faCheck} className="text-success w-100 mr-3" />
    ) : (
      <FontAwesomeIcon icon={faTimes} className="text-danger w-100 mr-3" />
    )

  const columns = [
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            isClearable
            onChange={onChange}
            api="/api/admins/prepaid_packages/autocomplete_name"
          />
        )
      },
    },
    {
      id: "hours",
      Header: "Hours",
      accessor: "hours",
      filterable: false,
    },
    {
      id: "price",
      Header: "Price",
      accessor: "price",
      filterable: false,
    },
    {
      id: "hourly_rate",
      Header: "Hourly Rate",
      accessor: "hourly_rate",
      filterable: false,
    },
    {
      id: "admin_only",
      Header: "Admin Only",
      accessor: "admin_only",
      filterable: false,
      sortable: false,
      Cell: booleanSymbol,
    },
    {
      id: "single_use",
      Header: "Single Use",
      accessor: "single_use",
      filterable: false,
      sortable: false,
      Cell: booleanSymbol,
    },
    {
      id: "amp",
      Header: "AMP",
      accessor: "amp",
      filterable: false,
      Cell: booleanSymbol,
    },
    {
      id: "partners",
      Header: "Partners",
      accessor: "partners",
      filterable: false,
      sortable: false,
      Cell: props => (
        <ul className="list-unstyled">
          {ordered(props.value, "last_name").map(partner => (
            <li key={partner.id}>
              <a href={partner.show_path}>{partner.full_name}</a>
            </li>
          ))}
        </ul>
      ),
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-evenly">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
          <Modal
            buttonText=""
            buttonClassName="btn btn-sm btn-outline-danger nc-icon nc-simple-remove"
            modalTitle="Remove"
            content={`<p class='lead'>Are you sure?</p>`}
            confirmURL={props.value.show_path}
            confirmText="Yes, remove it!"
            confirmClassName="btn btn-danger"
            confirmMethod="delete"
            cancelText="No, keep it for now"
            cancelClassName="btn btn-info"
          />
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default AdminPrepaidPackagesTable
