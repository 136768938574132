import React, { useRef } from "react"

import moment from "moment-timezone"
import Select from "react-select"
import ReactTable from "react-table"

import classNames from "src/classNames"
import colorGenerator from "src/colorGenerator"
import ordered from "src/ordered"

import {
  useExportable,
  useGradeOptions,
  useRemoteTableData,
} from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { DateRangePicker } from "components/Forms/DatePicker"
import LocalTime from "components/LocalTime"

import "react-table/react-table.css"

const OrganizationStudents = ({ organizationId }) => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/students/organization_index",
    additionalProps: { organization_id: organizationId },
  })

  const tableRef = useRef()
  const { exportToCSV } = useExportable({
    apiEndpoint: "/api/admins/students/organization_index",
    additionalProps: { organization_id: organizationId },
    tableRef: tableRef.current,
  })

  const gradeOptions = useGradeOptions()

  const columns = [
    {
      id: "full_name",
      Header: "Name",
      accessor: "",
      minWidth: 150,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/students/autocomplete_full_name"
          />
        )
      },
      Cell: props => (
        <a href={props.value.show_path}>{props.value.full_name}</a>
      ),
    },
    {
      id: "grade",
      Header: "Grade",
      sortable: true,
      accessor: "grade",
      minWidth: 125,
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={event => (event ? onChange(event.value) : onChange(""))}
            setValue={filter ? filter.value : "all"}
            options={gradeOptions}
            isClearable
          />
        )
      },
    },
    {
      id: "last_sign_in_at",
      Header: "Last Sign In",
      sortable: true,
      accessor: "last_sign_in_at",
      Cell: props =>
        props.value ? <LocalTime timestamp={props.value} /> : "Never",
      Filter: ({ onChange }) => (
        <DateRangePicker
          onChange={([startDate, endDate]) => {
            if (!startDate && !endDate) {
              onChange({ startsAt: null, endsAt: null })
              return
            }

            const startMoment = moment(startDate.toDate()).startOf("day")
            const endMoment = moment(endDate?.toDate() || startMoment).endOf(
              "day"
            )

            onChange({
              lastSignInAtStart: startMoment.format(),
              lastSignInAtEnd: endMoment.format(),
            })
          }}
        />
      ),
    },
    {
      id: "tags",
      Header: "Tags",
      accessor: "tags",
      sortable: false,
      minWidth: 125,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/students/autocomplete_tags"
          />
        )
      },
      Cell: props => {
        return (
          <div className="flex flex-wrap">
            {ordered(props.value, "name").map(tag => (
              <div
                key={tag.id}
                className={classNames(
                  colorGenerator(tag.name),
                  "my-0.5 mr-2 inline-block rounded-md py-0.5 px-1.5 font-semibold text-white"
                )}
              >
                {tag.name}
              </div>
            ))}
          </div>
        )
      },
    },
    {
      id: "study_groups",
      Header: "Study Groups",
      accessor: "study_groups",
      sortable: false,
      minWidth: 150,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/study_groups/autocomplete_name"
          />
        )
      },
      Cell: props => {
        return (
          <ul className="list-unstyled">
            {ordered(props.value, "name").map(studyGroup => (
              <li key={studyGroup.id}>{studyGroup.name}</li>
            ))}
          </ul>
        )
      },
    },
    {
      id: "tutor",
      Header: "Tutors",
      accessor: "tutors",
      sortable: false,
      minWidth: 150,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/tutors/autocomplete_full_name"
          />
        )
      },
      Cell: props => {
        return (
          <ul className="list-unstyled">
            {ordered(props.value, "last_name").map(tutor => (
              <li key={tutor.id}>
                <a href={tutor.show_path}>{tutor.full_name}</a>
              </li>
            ))}
          </ul>
        )
      },
    },
  ]

  return (
    <>
      <div className="row">
        <div className="col">
          <button onClick={exportToCSV} className="btn btn-success">
            Export to CSV
          </button>
        </div>
      </div>
      <ReactTable
        data={data}
        columns={columns}
        pages={pages}
        loading={loading}
        onFetchData={fetchData}
        defaultPageSize={20}
        ref={tableRef}
        manual
        filterable
        className="-striped -highlight"
      />
    </>
  )
}

export default OrganizationStudents
