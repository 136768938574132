import React, { useLayoutEffect, useRef, useState } from "react"

import { buildQuery, compress, useQuery } from "micro-graphql-react"
import PropTypes from "prop-types"
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory"

import classNames from "src/classNames"
import colorGenerator from "src/colorGenerator"

const TailoredProgress = props => {
  const { studentId } = props
  const [width, setWidth] = useState(0)
  const [activeTag, setActiveTag] = useState({ name: "Overall" })
  const wrappingContainer = useRef(null)

  useLayoutEffect(() => {
    setWidth(wrappingContainer.current.offsetWidth - 100)
    const resizeCallback = ev => {
      if (wrappingContainer == null) return
      setWidth(wrappingContainer.current.offsetWidth - 100)
    }
    window.addEventListener("resize", resizeCallback)
    return () => window.removeEventListener("resize", resizeCallback)
  }, [])

  let { loaded: studentTagsLoaded, data: studentTagsData } = useQuery(
    buildQuery(studentTagsQuery, { studentId })
  )

  let { loaded: chartDataLoaded, data: rawChartData } = useQuery(
    buildQuery(assignmentSummariesQuery, { studentId, tagId: activeTag.id })
  )

  const chartData = chartDataLoaded
    ? rawChartData.student.tailoredAssignmentSummaries.map(
        ({ tailoredAssignment, score, resultsPath }) => ({
          x: tailoredAssignment.name,
          y: score * 100,
          href: resultsPath,
        })
      )
    : null

  return (
    <div className="text-center" ref={wrappingContainer}>
      <h4>{activeTag.name}</h4>
      {chartDataLoaded && (
        <div className="text-center">
          <VictoryChart
            theme={VictoryTheme.material}
            width={width}
            containerComponent={<VictoryVoronoiContainer />}
          >
            <VictoryLine
              animate={true}
              data={chartData}
              domain={{ y: [0, 100] }}
              labelComponent={<VictoryTooltip />}
              labels={d => `${d.y.toFixed(2)}%`}
            />
            <VictoryAxis
              fixLabelOverlap={true}
              events={[
                {
                  target: "tickLabels",
                  eventHandlers: {
                    onClick: (e, props) => {
                      const datum = chartData[props.index]
                      window.location = datum.href
                    },
                  },
                },
              ]}
            />
            <VictoryAxis dependentAxis />
          </VictoryChart>
        </div>
      )}

      <div>
        <button
          className="btn"
          onClick={() => setActiveTag({ name: "Overall" })}
        >
          Overall
        </button>
        {studentTagsLoaded &&
          studentTagsData.student.tailoredTags.map(tailoredTag => (
            <button
              key={tailoredTag.id}
              className={classNames(colorGenerator(tailoredTag.name), "btn")}
              onClick={() => setActiveTag(tailoredTag)}
            >
              {tailoredTag.name}
            </button>
          ))}
      </div>
    </div>
  )
}

TailoredProgress.propTypes = {
  studentId: PropTypes.number.isRequired,
}

const studentTagsQuery = compress`
  query($studentId: ID!) {
    student(id: $studentId) {
      tailoredTags {
        id
        name
      }
    }
  }
`
const assignmentSummariesQuery = compress`
  query ($studentId: ID!, $tagId: ID) {
    student(id: $studentId) {
      tailoredAssignmentSummaries(tagId: $tagId) {
        tailoredAssignment {
          name
        }
        score
        resultsPath
      }
    }
  }
`

export default TailoredProgress
