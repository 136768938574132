import React from "react"

import ReactTable from "react-table"

import { css } from "@emotion/core"

import classNames from "src/classNames"
import colorGenerator from "src/colorGenerator"
import ordered from "src/ordered"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import LocalTime from "components/LocalTime"
import Modal from "components/Modal"

import "react-table/react-table.css"

const AdminBatchTextsTable = props => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/batch_texts",
  })

  const columns = [
    {
      id: "message",
      Header: "Message",
      accessor: "message",
      filterable: false,
      sortable: false,
      Cell: props => (
        <div
          css={css`
            overflow-x: scroll;
          `}
          dangerouslySetInnerHTML={{ __html: props.value }}
        />
      ),
    },
    {
      id: "send_at",
      Header: "Send At",
      accessor: "send_at",
      sortable: true,
      filterable: false,
      Cell: props => <LocalTime timestamp={props.value} />,
    },
    {
      id: "tag_list",
      Header: "Tags",
      accessor: "tags",
      sortable: false,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={selected =>
              onChange(selected.map(t => t.label).join(","))
            }
            api="/api/admins/batch_texts/autocomplete_tags"
            isMulti
          />
        )
      },
      Cell: props => (
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
          `}
        >
          {ordered(props.value, "name").map(tag => (
            <span
              key={tag.id}
              className={classNames(
                colorGenerator(tag.name),
                "my-0.5 mr-2 rounded-md py-0.5 px-1.5 font-semibold text-white"
              )}
            >
              {tag.name}
            </span>
          ))}
        </div>
      ),
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-evenly">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
          <Modal
            buttonText=""
            buttonClassName="btn btn-sm btn-outline-danger nc-icon nc-simple-remove"
            modalTitle="Remove"
            content={`<p class='lead'>Are you sure? This will cancel enqueued jobs.</p>`}
            confirmURL={props.value.remove_path}
            confirmText="Yes, remove them!"
            confirmClassName="btn btn-danger"
            confirmMethod="delete"
            cancelText="No, keep them for now"
            cancelClassName="btn btn-info"
          />
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default AdminBatchTextsTable
