import React, { useState } from "react"

import { NotificationManager } from "react-notifications"

import { gql, useMutation } from "hooks/urql"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

export type ClearLessonPlansModalProps = {
  organizationId: string
}

const ClearLessonPlansModal: React.FC<ClearLessonPlansModalProps> = ({
  organizationId,
}) => {
  const [error, setError] = useState<null | string>()
  const [, clearLessonPlans]: any[] = useMutation(clearLessonPlansMutation)

  const handleSubmit = closeModal => {
    clearLessonPlans({ organizationId })
      .then(
        result => {
          const { failures } = result.data.clearLessonPlans
          if (failures.length > 0) {
            setError(failures[0].first.message)
          } else {
            closeModal()
            NotificationManager.info("Lesson plans will be cleared momentarily")
          }
        },
        e => setError(e.message)
      )
      .catch(e => setError(e.message))
  }

  return (
    <ModalWithProvidedBody
      modalTitle="Clear Lesson Plans"
      buttonText="Clear Lesson Plans"
      buttonClassName="btn btn-danger"
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            <p>
              Are you sure you want to remove all lesson plan assignments from
              students in this organization? This action cannot be undone.
            </p>
          </div>

          <div className="modal-footer flex-col">
            {error && <div className="alert alert-danger p-3">{error}</div>}
            <div className="flex w-full justify-between">
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-info"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => handleSubmit(closeModal)}
                className="btn btn-danger"
              >
                Clear Lesson Plans
              </button>
            </div>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

const clearLessonPlansMutation = gql`
  mutation ($organizationId: ID!) {
    clearLessonPlans(organizationId: $organizationId) {
      failures {
        message
      }
    }
  }
`
export default ClearLessonPlansModal
