import React from "react"

import chromeDebug from "assets/cloudroom-chrome.png"
import safariDebug from "assets/cloudroom-safari.png"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"
import SupportModal from "components/SupportModal"

const ErrorModal = ({
  isOpen,
  onClose,
  error,
  browser,
  userType,
  phoneNumber,
  sessionId,
}) => {
  return (
    <ModalWithProvidedBody
      isOpen={isOpen}
      hideTrigger
      hideCloseButton
      modalClassName="bootstrap-modal"
      modalTitle="An Error Occurred"
      closeModal={onClose}
    >
      {({ closeModal }) => (
        <>
          <div className="modal-body">
            {error?.message.match(
              /permission denied|request is not allowed/i
            ) ? (
              <div>
                Your camera is blocked. Please follow these steps to unblock
                camera:
                {browser?.name === "safari" ? (
                  <img src={safariDebug} alt="Cloud Room Debug" />
                ) : (
                  <img src={chromeDebug} alt="Cloud Room Debug" />
                )}
                <ol className="pl-5 [&>li]:list-decimal">
                  {browser?.name === "safari" ? (
                    <>
                      <li>
                        Right Click (or hold control and click): URL Bar as
                        shown in the picture
                      </li>
                      <li>Click: Settings for This Website...</li>
                      <li>Find Camera and Microphone options near bottom</li>
                      <li>Change option to Allow</li>
                    </>
                  ) : (
                    <>
                      <li>Click the Settings Icon circled in the picture</li>
                      <li>Toggle Camera, Microphone and Sound to on</li>
                      <li>Click away from settings box</li>
                      <li>Click the "Reload" button that appears</li>
                    </>
                  )}
                </ol>
                <div>
                  If the above does not work, click contact below and a member
                  of our support team will reach out to help you resolve the
                  issue.
                </div>
              </div>
            ) : (
              <div>
                Please make sure you have a webcam plugged in and it is turned
                on. If this does not solve the issue, please restart your
                computer and then click the green button below to enter your
                phone number for support.
              </div>
            )}
          </div>
          <div className="modal-footer">
            <SupportModal
              error={error}
              browser={browser}
              userType={userType}
              phoneNumber={phoneNumber}
              sessionId={sessionId}
            />
            <button
              className="btn solid blue btn-info mr-1"
              onClick={closeModal}
            >
              Refresh
            </button>
          </div>
        </>
      )}
    </ModalWithProvidedBody>
  )
}

export default ErrorModal
