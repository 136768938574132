import React, { useRef } from "react"

import Select from "react-select"
import ReactTable from "react-table"

import classNames from "src/classNames"
import colorGenerator from "src/colorGenerator"
import { publishedOptions } from "src/enums"
import ordered from "src/ordered"

import { useRemoteTableData } from "hooks/remoteTable"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import Modal from "components/Modal"

import "react-table/react-table.css"

const AdminTutorsTable = () => {
  const { data, pages, loading, fetchData } = useRemoteTableData({
    apiEndpoint: "/api/admins/tutors",
  })
  const tutorFullNameSelect = useRef(null)
  const tutorEmailSelect = useRef(null)

  const clearableSelects = [
    tutorFullNameSelect.current,
    tutorEmailSelect.current,
  ]

  const columns = [
    {
      id: "full_name",
      Header: "Name",
      accessor: "full_name",
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={tutorFullNameSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/tutors/autocomplete_full_name"
          />
        )
      },
    },
    {
      id: "email",
      Header: "Email",
      accessor: "email",
      Cell: props => <a href={props.value.href}>{props.value.label}</a>,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            ref={tutorEmailSelect}
            clearableSelects={clearableSelects}
            onChange={onChange}
            api="/api/admins/tutors/autocomplete_email"
          />
        )
      },
    },
    {
      id: "phone_number",
      Header: "Phone Number",
      accessor: "phone_number",
      sortable: false,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={onChange}
            api="/api/admins/tutors/autocomplete_phone_number"
          />
        )
      },
    },
    {
      id: "tag_list",
      Header: "Tags",
      accessor: "tags",
      sortable: false,
      Filter: ({ filter, onChange }) => {
        return (
          <AutocompleteSelect
            onChange={selected =>
              onChange(selected.map(t => t.label).join(","))
            }
            api="/api/admins/tutors/autocomplete_tags"
            isMulti
          />
        )
      },
      Cell: props => (
        <div className="flex flex-wrap">
          {ordered(props.value, "name").map(tag => (
            <span
              key={tag.id}
              className={classNames(
                colorGenerator(tag.name),
                "my-0.5 mr-2 rounded-md py-0.5 px-1.5 font-semibold text-white"
              )}
            >
              {tag.name}
            </span>
          ))}
        </div>
      ),
    },
    {
      Header: "Published?",
      accessor: "published",
      Cell: props =>
        props.value ? (
          <i className="nc-icon nc-check-2 font-weight-bold text-success"></i>
        ) : (
          <i className="nc-icon nc-simple-remove font-weight-bold text-danger"></i>
        ),
      Filter: ({ filter, onChange }) => {
        return (
          <Select
            onChange={event => onChange(event.value)}
            setValue={filter ? filter.value : "all"}
            options={publishedOptions}
          />
        )
      },
    },
    {
      id: "actions",
      Header: "Actions",
      sortable: false,
      filterable: false,
      accessor: "actions",
      Cell: props => (
        <div className="flex flex-wrap justify-between">
          <a
            href={props.value.show_path}
            className="btn btn-sm btn-outline-info nc-icon nc-alert-circle-i"
          >
            <span className="hidden">View</span>
          </a>
          <a
            href={props.value.edit_path}
            className="btn btn-sm btn-outline-dark fa fa-pencil"
          >
            <span className="hidden">Edit</span>
          </a>
          <Modal
            buttonText=""
            buttonClassName="btn btn-sm btn-outline-danger nc-icon nc-simple-remove"
            modalTitle="Remove"
            content={`<p class='lead'>Are you sure? This will remove ${props.row["full_name"]} from all public listings</p>`}
            confirmURL={props.value.remove_path}
            confirmText="Yes, remove them!"
            confirmClassName="btn btn-danger"
            confirmMethod="delete"
            cancelText="No, keep them for now"
            cancelClassName="btn btn-info"
          />
        </div>
      ),
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      pages={pages}
      loading={loading}
      onFetchData={fetchData}
      defaultPageSize={20}
      manual
      filterable
    />
  )
}

export default AdminTutorsTable
