import React, { useState } from "react"

import { adminClient as client } from "src/urql-client"

import { gql, useMutation } from "hooks/urql"

import ModalWithProvidedBody from "components/ModalWithProvidedBody"

export type DeleteAssignmentModalProps = {
  id: string
  closeModal: VoidFunction
}

const DeleteAssignmentModal: React.FC<DeleteAssignmentModalProps> = ({
  id,
  closeModal,
}) => {
  const [errors, setErrors] = useState([])
  const [, runMutation]: any[] = useMutation(deleteAssignmentMutation, client)

  const deleteAssignment = tailoredAssignmentId => {
    runMutation({ id: tailoredAssignmentId })
      .then(
        result => {
          const { failures } = result.data.tailoredAssignmentDelete
          if (failures.length > 0) {
            setErrors(failures.map(f => f.message))
          } else {
            window.location.reload()
          }
        },
        () => setErrors(["Something went wrong"])
      )
      .catch(() => setErrors(["Something went wrong"]))
  }

  return (
    <>
      <ModalWithProvidedBody
        modalTitle="Delete Assignment"
        hideTrigger
        isOpen={!!id}
        closeModal={closeModal}
      >
        <>
          <div className="modal-body">
            <p>Are you sure you want to delete this assignment?</p>
          </div>
          <div className="modal-footer" style={{ flexFlow: "column" }}>
            <div className="w-100 d-flex justify-content-between">
              <button className="btn solid blue btn-info" onClick={closeModal}>
                Cancel
              </button>
              <button
                type="submit"
                className="btn solid red btn-danger"
                onClick={() => deleteAssignment(id)}
              >
                Delete
              </button>
            </div>

            {errors.length > 0 && <div>{errors.join(", ")}</div>}
          </div>
        </>
      </ModalWithProvidedBody>
    </>
  )
}

const deleteAssignmentMutation = gql`
  mutation DeleteAssignment($id: ID!) {
    tailoredAssignmentDelete(id: $id) {
      failures {
        message
      }
    }
  }
`

export default DeleteAssignmentModal
