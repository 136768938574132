import React, { useRef, useState } from "react"

import { motion } from "framer-motion"
import {
  buildMutation,
  buildQuery,
  compress,
  useMutation,
  useQuery,
} from "micro-graphql-react"

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ordered from "src/ordered"

import EditableList, { Item } from "components/EditableList"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import EditableDisplay from "components/Forms/EditableDisplay"
import WithLoadingIndicator from "components/WithLoadingIndicator"

const PrepaidPackageDetails = ({ id }) => {
  const [errorMessage, setErrorMessage] = useState()
  const partnerRef = useRef()
  const loadingState = useQuery(
    buildQuery(
      prepaidPackageQuery,
      { id },
      {
        onMutation: [
          {
            when: "updatePrepaidPackage",
            run: ({ softReset, currentResults }, response) => {
              if (response.updatePrepaidPackage?.prepaidPackage) {
                currentResults.prepaidPackage =
                  response.updatePrepaidPackage.prepaidPackage
                softReset(currentResults)
                setErrorMessage()
              } else if (response.updatePrepaidPackage?.errorMessages) {
                setErrorMessage(
                  response.updatePrepaidPackage.errorMessages.join(", ")
                )
              } else {
                setErrorMessage("Something went wrong")
              }
            },
          },
          {
            when: "prepaidPackageAddPartner",
            run: ({ softReset, currentResults }, response) => {
              if (response.prepaidPackageAddPartner?.prepaidPackage) {
                currentResults.prepaidPackage.partners =
                  response.prepaidPackageAddPartner.prepaidPackage.partners
                softReset(currentResults)
                setErrorMessage()
              } else if (response.prepaidPackageAddPartner?.errorMessages) {
                setErrorMessage(
                  response.prepaidPackageAddPartner.errorMessages.join(", ")
                )
              } else {
                setErrorMessage("Something went wrong")
              }
            },
          },
          {
            when: "prepaidPackageRemovePartner",
            run: ({ softReset, currentResults }, response) => {
              if (response.prepaidPackageRemovePartner?.prepaidPackage) {
                currentResults.prepaidPackage.partners =
                  response.prepaidPackageRemovePartner.prepaidPackage.partners
                softReset(currentResults)
                setErrorMessage()
              } else if (response.prepaidPackageRemovePartner?.errorMessages) {
                setErrorMessage(
                  response.prepaidPackageRemovePartner.errorMessages.join(", ")
                )
              } else {
                setErrorMessage("Something went wrong")
              }
            },
          },
        ],
      }
    )
  )

  const { runMutation } = useMutation(
    buildMutation(updatePrepaidPackageMutation)
  )
  const { runMutation: addPartner } = useMutation(
    buildMutation(addPartnerMutation)
  )

  return (
    <WithLoadingIndicator loadingState={loadingState}>
      {({ data }) => (
        <>
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
          <div className="row">
            <div className="col-12">
              <dl className="row">
                <dt className="col-6 col-md-4 col-lg-3">Name</dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={data.prepaidPackage.name}
                    save={({ value }) => runMutation({ id, name: value })}
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3">Hours</dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={data.prepaidPackage.hours}
                    save={({ value }) => runMutation({ id, hours: value })}
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3">Price</dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={data.prepaidPackage.formattedPrice}
                    editableValue={data.prepaidPackage.price}
                    save={({ value }) => runMutation({ id, price: value })}
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3">Hourly Rate</dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={data.prepaidPackage.formattedHourlyRate}
                    editableValue={data.prepaidPackage.hourlyRate}
                    save={({ value }) => runMutation({ id, hourlyRate: value })}
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3">
                  Admin Only (clients will be unable to purchase)
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      data.prepaidPackage.adminOnly ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success mr-3"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger mr-3"
                        />
                      )
                    }
                    editableValue={data.prepaidPackage.adminOnly}
                    type="checkbox"
                    save={({ value }) => runMutation({ id, adminOnly: value })}
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3">
                  Single Use (clients may only purchase once)
                </dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      data.prepaidPackage.singleUse ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success mr-3"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger mr-3"
                        />
                      )
                    }
                    editableValue={data.prepaidPackage.singleUse}
                    type="checkbox"
                    save={({ value }) => runMutation({ id, singleUse: value })}
                  />
                </dd>

                <dt className="col-6 col-md-4 col-lg-3">AMP</dt>
                <dd className="col-6 col-md-8 col-lg-9">
                  <EditableDisplay
                    displayValue={
                      data.prepaidPackage.amp ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success mr-3"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-danger mr-3"
                        />
                      )
                    }
                    editableValue={data.prepaidPackage.amp}
                    type="checkbox"
                    save={({ value }) => runMutation({ id, amp: value })}
                  />
                </dd>
              </dl>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h4>Partners</h4>
              <EditableList>
                {ordered(data.prepaidPackage.partners, "lastName").map(
                  partner => (
                    <motion.li key={partner.id} positionTransition>
                      <Item
                        className="justify-content-between"
                        mutation={removePartnerMutation}
                        mutationProps={{
                          id,
                          partnerId: partner.id,
                        }}
                      >
                        <a className="flex-grow-1" href={partner.showPath}>
                          {partner.fullName}
                        </a>
                      </Item>
                    </motion.li>
                  )
                )}
              </EditableList>

              <AutocompleteSelect
                ref={partnerRef}
                api="/api/admins/partners/autocomplete_full_name"
                placeholder="Add Partner"
                onChange={selected =>
                  selected &&
                  addPartner({
                    id,
                    partnerId: selected.value,
                  }).then(r => partnerRef.current.clearValue())
                }
              />
            </div>
          </div>
        </>
      )}
    </WithLoadingIndicator>
  )
}

const packageFields = compress`
  fragment PackageFields on PrepaidPackage {
    name
    hours
    price
    formattedPrice
    hourlyRate
    formattedHourlyRate
    adminOnly
    singleUse
    amp
    partners {
      id
      fullName
      lastName
      showPath
    }
  }
`

const prepaidPackageQuery = compress`
  ${packageFields}
  query($id: ID!) {
    prepaidPackage(id: $id) {
      ...PackageFields
    }
  }
`

const updatePrepaidPackageMutation = compress`
  ${packageFields}
  mutation(
    $id: ID!
    $name: String
    $hours: String
    $price: String
    $adminOnly: Boolean
    $singleUse: Boolean
    $amp: Boolean
    $hourlyRate: String
  ) {
    updatePrepaidPackage(
      id: $id
      name: $name
      hours: $hours
      price: $price
      adminOnly: $adminOnly
      singleUse: $singleUse
      amp: $amp
      hourlyRate: $hourlyRate
    ) {
      errorMessages
      prepaidPackage {
        ...PackageFields
      }
    }
  }
`

const addPartnerMutation = compress`
  ${packageFields}
  mutation($id: ID!, $partnerId: ID!) {
    prepaidPackageAddPartner(id: $id, partnerId: $partnerId) {
      errorMessages
      prepaidPackage {
        ...PackageFields
      }
    }
  }
`

const removePartnerMutation = compress`
  ${packageFields}
  mutation($id: ID!, $partnerId: ID!) {
    prepaidPackageRemovePartner(id: $id, partnerId: $partnerId) {
      errorMessages
      prepaidPackage {
        ...PackageFields
      }
    }
  }
`

export default PrepaidPackageDetails
