import React, { useState } from "react"

import { Tooltip } from "@chakra-ui/core"
import {
  EyeIcon,
  PencilSquareIcon,
  Square2StackIcon,
  SquaresPlusIcon,
  TrashIcon,
} from "@heroicons/react/24/solid"

import classNames from "src/classNames"
import colorGenerator from "src/colorGenerator"
import { adminClient as client } from "src/urql-client"

import { gql, useQuery } from "hooks/urql"

import BasicDisplay from "components/BasicDisplay"
import LocalTime from "components/LocalTime"
import Pagination from "components/Pagination"
import UrqlLoadingIndicator from "components/WithLoadingIndicator/urql"

import Students from "../Students"

import DeleteAssignmentModal from "./DeleteAssignmentModal"
import DuplicateAssignment from "./DuplicateAssignmentModal"
import TailoredAssignmentUserExport from "./Export"

export type TailoredAssignmentsIndexProps = Record<string, never>

const TailoredAssignmentsIndex: React.FC<
  TailoredAssignmentsIndexProps
> = () => {
  const [page, setPage] = useState(1)
  const [searchName, setSearchName] = useState("")
  const [duplicateId, setDuplicateId] = useState<null | string>(null)
  const [deleteId, setDeleteId] = useState<null | string>(null)
  const [result] = useQuery({
    query: tailoredAssignmentsQuery,
    client,
    variables: { name: searchName, page },
  })

  return (
    <div className="mt-6">
      <div className="form-group">
        <label className="form-label w-72">
          <span>Search Assignments</span>
          <input
            className="form-control"
            value={searchName}
            onChange={e => setSearchName(e.currentTarget.value)}
          />
        </label>
      </div>

      <TailoredAssignmentUserExport />

      <DuplicateAssignment
        id={duplicateId}
        closeModal={() => setDuplicateId(null)}
      />
      <DeleteAssignmentModal
        id={deleteId}
        closeModal={() => setDeleteId(null)}
      />

      <UrqlLoadingIndicator result={result}>
        {({ data: { tailoredAssignments } }) => (
          <>
            <ul className="list-none p-0">
              {tailoredAssignments.data.map(assignment => (
                <li
                  key={assignment.id}
                  className="border-t-0 border-l-0 border-r-0 border-b-2 border-solid border-gray-300 py-4"
                >
                  <div className="flex items-center space-x-4">
                    <div className="text-xl font-bold">{assignment.name}</div>
                    <Tooltip
                      label="Preview Assignment"
                      className="rounded-md bg-gray-600 py-1 px-2 text-white"
                      aria-label="Preview Assignment"
                    >
                      <a
                        href={assignment.showPath}
                        className="flex items-center"
                      >
                        <EyeIcon className="h-6 w-6 text-blue-400" />
                      </a>
                    </Tooltip>
                    <Tooltip
                      label="Edit Assignment"
                      className="rounded-md bg-gray-600 py-1 px-2 text-white"
                      aria-label="Edit Assignment"
                    >
                      <a
                        href={assignment.editPath}
                        className="flex items-center"
                      >
                        <PencilSquareIcon className="h-6 w-6 text-blue-400" />
                      </a>
                    </Tooltip>
                    <Tooltip
                      label="Duplicate Assignment"
                      className="rounded-md bg-gray-600 py-1 px-2 text-white"
                      aria-label="Duplicate Assignment"
                    >
                      <Square2StackIcon
                        className="h-6 w-6 cursor-pointer text-blue-400"
                        onClick={() => setDuplicateId(assignment.id)}
                      />
                    </Tooltip>
                    <Tooltip
                      label="Delete Assignment"
                      className="rounded-md bg-gray-600 py-1 px-2 text-white"
                      aria-label="Delete Assignment"
                    >
                      <button
                        className="flex items-center border-none"
                        onClick={() => setDeleteId(assignment.id)}
                      >
                        <TrashIcon className="h-6 w-6 cursor-pointer text-red-500" />
                      </button>
                    </Tooltip>
                  </div>

                  <div className="my-3 text-lg">
                    <span className="font-semibold">Due Date: </span>{" "}
                    {assignment.dueAt ? (
                      <LocalTime omitTime timestamp={assignment.dueAt} />
                    ) : (
                      "None"
                    )}
                  </div>

                  <span className="text-lg font-semibold">Sections</span>
                  <ul className="flex list-none flex-wrap p-0">
                    {assignment.tailoredSections.map(section => (
                      <li
                        key={section.id}
                        className="border-1 my-2 mr-8 rounded-lg border-solid border-gray-300 bg-neutral-50 p-3 shadow-sm"
                      >
                        <div className="flex w-full items-center justify-between">
                          <span className="text-lg font-semibold">
                            {section.name}
                          </span>
                          <Tooltip
                            label="Select Questions"
                            className="rounded-md bg-gray-600 py-1 px-2 text-white"
                            aria-label="Select Questions"
                          >
                            <a
                              href={section.editPath}
                              className="flex items-center"
                            >
                              <SquaresPlusIcon className="ml-2 h-6 w-6 text-blue-400" />
                            </a>
                          </Tooltip>
                        </div>

                        <BasicDisplay label="Questions (selected / max)">
                          {section.questionCount} / {section.maxQuestions}
                        </BasicDisplay>
                        <BasicDisplay label="Tags">
                          <div className="flex flex-wrap">
                            {section.tags.map(tag => (
                              <div
                                key={tag.id}
                                className={classNames(
                                  colorGenerator(tag.name),
                                  "my-0.5 mr-2 rounded-md py-0.5 px-1.5 font-semibold text-white"
                                )}
                              >
                                {tag.name}
                              </div>
                            ))}
                          </div>
                        </BasicDisplay>
                      </li>
                    ))}
                  </ul>

                  <Students id={assignment.id} />

                  {assignment.averageScore ? (
                    <div className="mt-4 flex items-center space-x-4 text-lg">
                      <div>
                        <span className="font-semibold">Average Score: </span>
                        {Math.round(assignment.averageScore * 100)}%
                      </div>
                      <a
                        href={assignment.overallResultsPath}
                        className="btn btn-sm"
                      >
                        View Results
                      </a>
                      <TailoredAssignmentUserExport
                        tailoredAssignmentId={assignment.id}
                      />
                    </div>
                  ) : null}
                </li>
              ))}
            </ul>

            <Pagination
              totalPages={tailoredAssignments.totalPages}
              currentPage={tailoredAssignments.currentPage}
              changePage={setPage}
            />
          </>
        )}
      </UrqlLoadingIndicator>
    </div>
  )
}

const tailoredAssignmentsQuery = gql`
  query TailoredAssignments($name: String, $page: Int) {
    tailoredAssignments(name: $name, page: $page) {
      currentPage
      totalPages
      data {
        id
        name
        dueAt
        averageScore
        showPath
        editPath
        overallResultsPath
        tailoredSections {
          id
          name
          editPath
          maxQuestions
          questionCount
          tags {
            id
            name
          }
        }
      }
    }
  }
`

export default TailoredAssignmentsIndex
