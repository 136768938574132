import React, { useEffect, useRef, useState } from "react"

import axios from "src/axios"

import CollapsibleContainer from "components/CollapsibleContainer"
import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import RemoveButton from "components/RemoveButton"

const TailoredAssignmentStudents = ({ id }) => {
  const selectRef = useRef(null)
  const [students, setStudents] = useState([])
  useEffect(() => {
    axios.get(`/api/admins/tailored_assignments/${id}`).then(response => {
      const { students } = response.data
      setStudents(students)
    })
  }, [id])

  const onChange = value => {
    if (value === null) return
    axios
      .post(`/api/admins/tailored_assignments/${id}/add_student`, {
        student_id: value.value,
      })
      .then(response => {
        const { students } = response.data
        setStudents(students)
        selectRef.current.ref.select.setState({ value: null })
      })
  }

  const removeStudent = student => {
    axios
      .delete(`/api/admins/tailored_assignments/${id}/remove_student`, {
        data: { student_id: student.id },
      })
      .then(response => {
        const { students } = response.data
        setStudents(students)
      })
  }

  const spacer = <span className="mr-1 ml-1">|</span>

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          {students.length > 0 ? (
            <CollapsibleContainer
              title={
                <div className="text-lg font-semibold">
                  View Assigned Students
                </div>
              }
              childrenClassName="ml-3"
            >
              <ul className="list-unstyled">
                {students.map(student => (
                  <li key={student.id} className="mb-3">
                    <div className="d-flex align-items-center">
                      <RemoveButton onClick={() => removeStudent(student)} />
                      <a href={student.path}>{student.fullName}</a>
                      {spacer}
                      <a href={student.resultsPath}>Section Results</a>
                      {spacer}
                      <a href={student.progressPath}>Overall Progress</a>
                      {spacer}
                      <a href={student.generateAssignmentPath}>
                        Generate Assignment
                      </a>
                      {spacer}
                      {student.assignmentState === "initial" && (
                        <span>Score: Not Started</span>
                      )}
                      {student.assignmentState === "in_progress" && (
                        <span>Score: In Progress</span>
                      )}
                      {student.assignmentState === "completed" && (
                        <span>Score: {(student.score * 100).toFixed(2)}%</span>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </CollapsibleContainer>
          ) : (
            <div className="my-2 italic">No students assigned</div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <AutocompleteSelect
            ref={selectRef}
            api={"/api/admins/students/autocomplete_full_name"}
            onChange={onChange}
            placeholder="Add Student..."
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default TailoredAssignmentStudents
